<template>
  <div>这里是问答显示页</div>
</template>

<script>
export default {
    name:'QueAns'
}
</script>

<style>

</style>